import { onLogout as onLogoutAuthenticator } from './logout';
import { getAuthCookie } from './cookie';
import { getTokenServer } from './token';

export const NOT_AUTH_HTTP_RESPONSE_CODE = 401;

export const authInterceptor = (onLogout: () => void) => {
    onLogoutAuthenticator(onLogout);
};

export const isAuthenticatedClient = () => !!getAuthCookie();

export const isAuthenticatedServer = () => !!getTokenServer();

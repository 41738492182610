import { ButtonColor } from '@/components/button/Button';

export enum HeaderColor {
    WHITE = 'WHITE',
    BLACK = 'BLACK',
}

export interface IHeaderColor {
    headerColor: HeaderColor;
    backButtonColor?: ButtonColor;
}

export interface IHeaderButtons {
    headerColor?: HeaderColor;
    headerButtons?: {
        contactButton?: boolean;
        accountButton?: boolean;
        backButton?: boolean;
        backButtonColor?: ButtonColor;
    };
}

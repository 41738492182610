import React from 'react';
import classNames from 'classnames';
import styles from './Select.module.scss';
import { useTranslation } from 'next-i18next';
import { useMobileView } from '@/hooks/useIsInMobileView';
import { Modal } from '@/components/modal/Modal';
import SelectOptions from '@/components/select/select-options/SelectOptions';
import { useModal } from '@/components/modal/modal-hooks/useModal';

export enum SelectColor {
    TRANSPARENT_WHITE = 'transparent-white',
    TRANSPARENT_BLACK = 'transparent-black',
}

interface ISelect {
    selectColor: SelectColor;
    selectOptions: string[];
    initSelectedOption: string;
    classes?: string;

    onSelect?(selectedOption: string): void;
}

const Select: React.FC<ISelect> = ({ selectColor, selectOptions, initSelectedOption, classes, onSelect }) => {
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const isInMobileView = useMobileView();
    const { closeModal, openModal, isModalOpen } = useModal();

    const [isOpenSelect, setSelectOpen] = React.useState(false);
    const [selectedOption, setSelectedOption] = React.useState(initSelectedOption);

    const toggleSelect = () => {
        if (isInMobileView) {
            return openModal();
        }

        return setSelectOpen(!isOpenSelect);
    };

    const selectHandler = (selectedOption: string) => () => {
        setSelectedOption(selectedOption);
        isInMobileView ? closeModal() : setSelectOpen(!isOpenSelect);

        onSelect?.(selectedOption);
    };

    return (
        <div className={styles.wrapper}>
            <div
                data-testid={'selectButton'}
                onClick={toggleSelect}
                className={classNames(styles.wrapperSelect, classes, {
                    [styles.wrapperSelectTransparentWhite]: selectColor === SelectColor.TRANSPARENT_WHITE,
                    [styles.wrapperSelectTransparentBlack]: selectColor === SelectColor.TRANSPARENT_BLACK,
                })}>
                {selectedOption}
                <span
                    className={classNames(styles.wrapperSelectChevron, {
                        [styles.wrapperSelectChevronDown]: isOpenSelect,
                    })}
                />
            </div>
            {isModalOpen && isInMobileView && (
                <Modal isClosable={true} classes={styles.wrapperListModal} onClose={closeModal}>
                    <div className={styles.wrapperListModalTitle}>{t('translations.langSwitcher.modal.title')}</div>
                    <SelectOptions
                        selectedOption={selectedOption}
                        selectOptions={selectOptions}
                        selectColor={selectColor}
                        selectHandler={selectHandler}
                    />
                </Modal>
            )}
            {isOpenSelect && !isInMobileView && (
                <SelectOptions selectOptions={selectOptions} selectColor={selectColor} selectHandler={selectHandler} />
            )}
        </div>
    );
};

export default Select;

import { decodeToken } from './jwtDecoder';

export const isValidToken = (token?: string) => {
    if (!token) {
        return false;
    }

    const decodedJwt = decodeToken(token);

    if (!decodedJwt?.refreshTimeout) {
        return false;
    }

    const currentTime = Date.now() / 1000;

    return decodedJwt?.refreshTimeout > currentTime;
};

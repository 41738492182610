import React from 'react';
import styles from './LangSwitcher.module.scss';
import Select, { SelectColor } from '@/components/select/Select';
import { HeaderColor, IHeaderColor } from '@/components/header/header.enums';
import { getDomainUrlByLang, getSelectOptions } from '@/components/header/lang-switcher/langSwitcher.helper';
import { MarketSymbol } from '@/services/language.service';
import { useTranslation } from '@app/i18n/useTranslationClient';

export const LangSwitcher: React.FC<IHeaderColor> = ({ headerColor }) => {
    const {
        i18n: { language },
    } = useTranslation();

    const handleOnSelect = React.useCallback(
        (selectedOption: string) => (window.location.href = getDomainUrlByLang(selectedOption)),
        []
    );

    return (
        <Select
            selectOptions={getSelectOptions()}
            onSelect={handleOnSelect}
            initSelectedOption={MarketSymbol[language]}
            selectColor={colorsMap[headerColor]}
            classes={styles.langSwitcher}
        />
    );
};

const colorsMap = {
    [HeaderColor.WHITE]: SelectColor.TRANSPARENT_WHITE,
    [HeaderColor.BLACK]: SelectColor.TRANSPARENT_BLACK,
};

import React from 'react';
import styles from '../Header.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import LogoMed from '@/assets/images/logo/logoMedicine.svg';
import LogoMedBlack from '@/assets/images/logo/logoMedicineBlack.svg';
import { translate } from '@/translations/static/staticTranslations';
import { HeaderColor, IHeaderColor } from '@/components/header/header.enums';
import { useTranslation } from '@app/i18n/useTranslationClient';

export const Logo: React.FC<IHeaderColor> = ({ headerColor }) => {
    const {
        i18n: { language },
    } = useTranslation();

    const logoImage = headerColor === HeaderColor.WHITE ? LogoMed : LogoMedBlack;

    return (
        <Link href={translate(language, 'routes.home')} className={styles.headerLogoLink}>
            <Image src={logoImage} width={192} height={28} alt="Logo" className={styles.headerLogo} />
        </Link>
    );
};

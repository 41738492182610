'use client';

import React from 'react';
import styles from '../Header.module.scss';
import { Button, ButtonColor, ButtonType } from '@/components/button/Button';
import { translate } from '@/translations/static/staticTranslations';
import { useTranslation } from '@app/i18n/useTranslationClient';
import { HeaderColor, IHeaderColor } from '@/components/header/header.enums';
import { ShowOnDesktopOnly } from '@/components/show-on/ShowOn';

export const BackButton: React.FC<IHeaderColor> = ({ headerColor, backButtonColor }) => {
    const {
        t,
        i18n: { language },
    } = useTranslation();

    const buttonColor = backButtonColor
        ? backButtonColor
        : headerColor === HeaderColor.WHITE
        ? ButtonColor.WHITE
        : ButtonColor.BLACK;

    return (
        <ShowOnDesktopOnly>
            <Button
                classes={styles.headerButtonContainerBtn}
                buttonType={ButtonType.LINK}
                url={translate(language, 'routes.home')}
                buttonColor={buttonColor}>
                {t('translations.header.back')}
            </Button>
        </ShowOnDesktopOnly>
    );
};

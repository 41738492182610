import React from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export const useModal = () => {
    const [isModalOpen, setModalOpen] = React.useState(false);

    const openModal = () => {
        setModalOpen(true);
        disableBodyScroll();
    };

    const closeModal = () => {
        setModalOpen(false);
        enableBodyScroll();
    };

    const toggleModal = () => {
        if (isModalOpen) {
            return closeModal();
        }

        openModal();
    };

    return {
        isModalOpen,
        setModalOpen,
        openModal,
        closeModal,
        toggleModal,
    };
};

import { getAuthCookie, removeAuthCookie } from './cookie';
import { AUTH_COOKIE_NAME } from './config';
import { isValidToken } from './tokenValidator';

export const getTokenClient = () => {
    const token = getAuthCookie();

    if (token && !isValidToken(token)) {
        removeAuthCookie();
        return;
    }

    return token;
};

export const getTokenServer = () => {
    try {
        const { cookies } = require('next/headers');
        const token: { name: string; value: string } = cookies().get(AUTH_COOKIE_NAME);

        if (isValidToken(token.value)) {
            return token.value;
        }
    } catch (e) {
        new Error('getTokenServer failed');
    }
};

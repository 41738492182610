/* eslint-disable @typescript-eslint/no-unsafe-argument */

import React from 'react';
import styles from './ShowOn.module.scss';
import classNames from 'classnames';
import { WithChildren } from '@/utils/interfaces';

const cloneElement = (element: React.ReactElement, props: { className: string }) =>
    React.cloneElement(element, {
        ...element?.props,
        className: classNames(element?.props.className, props.className),
    });

export const ShowOnMobileOnly: React.FC<WithChildren<React.ReactElement>> = ({ children }) =>
    cloneElement(children, { className: styles.showOnMobileOnly });

export const ShowOnDesktopOnly: React.FC<WithChildren<React.ReactElement>> = ({ children }) =>
    cloneElement(children, { className: styles.showOnDesktopOnly });

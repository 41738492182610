import React from 'react';
import classNames from 'classnames';
import styles from '@/components/select/Select.module.scss';
import { SelectColor } from '@/components/select/Select';

interface ISelectOptions {
    selectOptions: string[];
    selectColor: SelectColor;
    selectHandler(selectedOption: string): () => void;
    selectedOption?: string;
}

const SelectOptions: React.FC<ISelectOptions> = ({ selectOptions, selectColor, selectHandler, selectedOption }) => (
    <ul
        className={classNames(styles.wrapperList, {
            [styles.wrapperListWhite]: selectColor === SelectColor.TRANSPARENT_WHITE,
            [styles.wrapperListBlack]: selectColor === SelectColor.TRANSPARENT_BLACK,
        })}>
        {selectOptions.map((selectOption, index) => (
            <li
                className={classNames({
                    [styles.wrapperListActive]: selectedOption === selectOption,
                })}
                data-testid={'selectOption'}
                key={index}
                onClick={selectHandler(selectOption)}>
                {selectOption}
            </li>
        ))}
    </ul>
);

export default SelectOptions;

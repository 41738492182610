import decode from 'jwt-decode';

export const decodeToken = (token: string): IDecodedToken | void => {
    if (token?.length) {
        try {
            return decode(token);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    }

    return undefined;
};

'use client';

import React from 'react';
import styles from './Header.module.scss';
import Container from '@/components/Container/Container';
import { IHeaderButtons, IHeaderColor } from '@/components/header/header.enums';
import { LoginButton } from '@/components/header/login-button/LoginButton';
import { LangSwitcher } from '@/components/header/lang-switcher/LangSwitcher';
import { ContactButton } from '@/components/header/contact-button/ContactButton';
import { BackButton } from '@/components/header/back-button/BackButton';
import { Logo } from '@/components/header/logo/Logo';

export const Header: React.FC<IHeaderColor & IHeaderButtons> = ({ headerColor, headerButtons }) => {
    const { contactButton, accountButton, backButton } = {
        contactButton: true,
        accountButton: true,
        backButton: false,
        ...headerButtons,
    };

    return (
        <Container classes={styles.container}>
            <div className={styles.header}>
                <Logo headerColor={headerColor} />
                <div className={styles.headerButtonContainer}>
                    {contactButton && <ContactButton headerColor={headerColor} />}
                    {accountButton && <LoginButton headerColor={headerColor} />}
                    {backButton && (
                        <BackButton backButtonColor={headerButtons?.backButtonColor} headerColor={headerColor} />
                    )}
                    <LangSwitcher headerColor={headerColor} />
                </div>
            </div>
        </Container>
    );
};

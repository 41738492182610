import { setAuthCookie } from './cookie';

export const onLogin = (config?: ILoginConfig) => {
    if (config?.token) {
        setAuthCookie(config?.token);

        setTimeout(() => {
            config?.onLogin?.();
        }, 500);
    }
};

'use client';

import React from 'react';
import styles from '../Header.module.scss';
import { Button, ButtonColor, ButtonType } from '@/components/button/Button';
import { translate } from '@/translations/static/staticTranslations';
import { useTranslation } from '@app/i18n/useTranslationClient';
import Image from 'next/image';
import Link from 'next/link';
import { HeaderColor, IHeaderColor } from '@/components/header/header.enums';
import { ShowOnDesktopOnly, ShowOnMobileOnly } from '@/components/show-on/ShowOn';
import { useAuthContent } from '@/hooks/useAuthContent';

export const LoginButton: React.FC<IHeaderColor> = ({ headerColor }) => {
    const {
        t,
        i18n: { language },
    } = useTranslation();

    const url = useAuthContent(translate(language, 'routes.login'), translate(language, 'routes.account'));

    const label = useAuthContent(t('translations.header.login'), t('translations.header.account'));

    const icon = headerColor === HeaderColor.WHITE ? '/icons/user.svg' : '/icons/userBlack.svg';

    return (
        <>
            <ShowOnMobileOnly>
                <Link href={url} className={styles.headerButtonContainerLink} data-testid={'loginMobileLink'}>
                    <Image src={icon} alt={'login link'} width={24} height={24} />
                </Link>
            </ShowOnMobileOnly>
            <ShowOnDesktopOnly>
                <Button
                    testId={'loginDesktopBtn'}
                    classes={styles.headerButtonContainerBtn}
                    buttonType={ButtonType.LINK}
                    url={url}
                    buttonColor={ButtonColor.BLACK}>
                    {label}
                </Button>
            </ShowOnDesktopOnly>
        </>
    );
};

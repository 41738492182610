import React from 'react';
import { isAuthenticatedClient } from '@packages/authenticator';

export const useAuthContent = (nonAuthContent: string, authContent: string) => {
    const isAuthenticated = isAuthenticatedClient();
    const [newContent, setNewContent] = React.useState(nonAuthContent);

    React.useEffect(() => {
        if (isAuthenticated) {
            setNewContent(authContent);
        }
    }, []);

    return newContent;
};

'use client';

import React from 'react';
import styles from '../Header.module.scss';
import { HeaderColor, IHeaderColor } from '@/components/header/header.enums';
import Image from 'next/image';
import Link from 'next/link';
import { Button, ButtonColor, ButtonType } from '@/components/button/Button';
import { translate } from '@/translations/static/staticTranslations';
import { useTranslation } from '@app/i18n/useTranslationClient';
import { ShowOnDesktopOnly, ShowOnMobileOnly } from '@/components/show-on/ShowOn';
import { useAuthContent } from '@/hooks/useAuthContent';

export const ContactButton: React.FC<IHeaderColor> = ({ headerColor }) => {
    const {
        t,
        i18n: { language },
    } = useTranslation();

    const contactUrl = useAuthContent(
        translate(language, 'routes.howItWorksContact'),
        translate(language, 'routes.accountContact')
    );

    const isHeaderWhite = headerColor === HeaderColor.WHITE;
    const icon = isHeaderWhite ? '/icons/contact.svg' : '/icons/contactBlack.svg';
    const buttonColor = isHeaderWhite ? ButtonColor.TRANSPARENT_WHITE : ButtonColor.TRANSPARENT_BLACK;

    return (
        <>
            <ShowOnMobileOnly>
                <Link href={contactUrl} className={styles.headerButtonContainerLink}>
                    <Image src={icon} alt={'contact link'} width={24} height={24} />
                </Link>
            </ShowOnMobileOnly>
            <ShowOnDesktopOnly>
                <Button
                    classes={styles.headerButtonContainerBtn}
                    buttonType={ButtonType.LINK}
                    url={contactUrl}
                    buttonColor={buttonColor}>
                    {t('translations.header.contact')}
                </Button>
            </ShowOnDesktopOnly>
        </>
    );
};
